.loader_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999 !important;


    .loader_inner_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 60px;
            height: 60px;
        }

    }

}