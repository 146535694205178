.mapclass {
  height: calc(100vh - 100px);
  overflow: hidden;

  .mapContent {
    height: 100%;

    .sidebar {
      // position: absolute;
      // top: 0;
      // left: 0;
      // bottom: 0;
      // width: 300px;
      // background-color: white;
      // box-sizing: 2px 0px 5px rgpa(0, 0, 0, 0.3);
      // transition: transform 0.3 ease-in-out;
      // transform: translateX(-300px);

      position: absolute;
      top: 125px;
      bottom: 0;
      left: 10px;
      width: 280px;
      background-color: aliceblue;
      box-sizing: 2px 0px 5px rgpa(0, 0, 0, 0.3);
      transition: transform 0.3 ease-in-out;
      transform: translateX(-300px);
      z-index: 1000;
      padding: 20px;
      border: 1px solid rgba(0,0,0,0.2);

      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
      text-align: left;


    }
    .sidebar.open {
      transform: translateX(0);
    }

    .sidebar-toggle {
      
    position: absolute;
    top: 133px;
    left: 10px;
    z-index: 9999;
    width: 35px;
    height: 40px;
    border: none;
    background-color: white;
    border-radius: 4px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, -0.35);

    }


    .map-container {
      position: relative;
      height: 100vh;
      width: 100%;
    }
    
    .sidebar {
      position: absolute;
      top: 133px;
      right: 0;
      height: 75%;
      width: 300px;
      background-color: #fff;
    }
    
    /* Media query for responsiveness */
    @media (max-width: 768px) {
      .sidebar {
        width: 100%;
      }
    }

    

    .sidebar-toggle:before {
      // content: '\2630';
    }

    #hamburger {
        margin-top: 50px;
      }

    #hamburger ul {
      list-style-type: none;
      padding: 0;
    }

    #hamburger li {
      margin: 0;
      
    }

    #hamburger li a {
      display: block;
      padding: 20px;
      text-decoration: none;
      color: #333;
      font-weight: 500;
      font-size: 17px;
    }

    #hamburger li a:hover {
      background-color: #0F3787;
      color: #fff;
    }
  }

  .playerClass {
    display: none;
    /* display: none !important; */
    width: 50%;
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* border: 2px solid red; */
    margin-bottom: 40px;
    z-index: 999;

    .playercontainer {
      display: flex;
      /* justify-content: center; */
      align-items: center;
      /* border: 2px solid red; */
      background: #0f3787 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 36px #00000042;
      border-radius: 14px;
      /* border-radius: 50px; */

      img {
        width: 70px;
        border-radius: 50px;
        padding: 10px;
        height: 70px;
      }
    }
  }

  .playPauseBtn {
    position: absolute;
    top: 100px;
    right: 10px;
    z-index: 99999;
    cursor: pointer;
    display: none;

    img {
      width: 50px;
    }

    #playIconBtn {
      display: none;
    }
  }

  .videoContent {
    position: absolute;
    bottom: 0;
    z-index: 99999;
    // z-index: 999;
    padding: 0 !important;
    margin: 0 !important;

    // display: none;
    
    @media only screen and (max-width: 575px) {
      position: absolute;
      bottom: 0;
      z-index: 998;
      padding: 0 !important;
      margin: 0 !important;
    }

    .videoCol {
      padding: 0 !important;
      margin: 0 !important;

      video {
        width: -webkit-fill-available;
        height: auto;
        padding: 25px;
      }
    }

    .hideVid {
      pointer-events: none;
      opacity: 0.7;

      @media only screen and (max-width: 575px) {
        display: none;
      }
    }

    @media only screen and (max-width: 575px) {
      .hideVidMain {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 100% !important;
      }

      .responsive-iframe {
        // border: 1px solid red !important;
        position: relative !important;
        left: 25px !important;
      }
    }

    /* Then style the iframe to fit in the container div with full height and width */
    .responsive-iframe {
      position: relative !important;
      top: 25px !important;
      left: 0;
      bottom: 0;
      // border: 1px solid red !important;
    }

    .videowrapper {
      float: none;
      clear: both;
      width: 90%;
      height: 80%;
      // position: relative;
      // padding-bottom: 56.25%;
      // padding-top: 25px;
    }
    .videowrapper iframe {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    .leaflet-left {
      display: none !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .container-fluid.videoContent {
      // z-index: 99999;
    }
  }

  .userForm {
    height: 100%;
    // border: 2px solid red;
    position: absolute;
    width: 100%;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .fs-style {
      font-size: 14px;
    }
    .formContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #0f3787 !important;
      height: 50%;
      margin-bottom: 118px;

      @media only screen and (max-width: 900px) {
        width: 80%;
        height: 35%;
        margin-bottom: 0;
      }

      @media only screen and (max-width: 575px) {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        padding: 25px 0 85px;
        width: 90%;
        margin-bottom: 0;
      }

      @media only screen and (max-height: 450px) and (max-width: 900px) {
        height: auto;
      }

      @media only screen and (max-height: 768px) and (max-width: 1100px) {
        height: auto;
      }

      .formContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        color: white;
        font: normal normal normal 28px/32px Times New Roman;

        .formContent1 {
          font-size: 2rem;
          margin-top: 40px;

          // font-family: "Trebuchet MS";
          font-family: Verdana, Geneva, sans-serif;

          @media only screen and (max-width: 1700px) {
            font-size: 1.7rem;
          }

          @media only screen and (max-width: 1400px) {
            font-size: 1.3rem;
          }

          @media only screen and (max-width: 900px) {
            font-size: 1.2rem;
          }

          @media only screen and (max-width: 575px) {
            font-size: 1.1rem;
            margin: 0;
          }
        }

        .formContent2 {
          margin-top: 100px;
          margin-bottom: auto;
          font-family: Verdana, Geneva, sans-serif;
          font-size: 15px;

          // font-family: "Trebuchet MS";

          @media only screen and (max-width: 1700px) {
            // font-size: 1.3rem;
            font-size: 15px;
          }

          @media only screen and (max-width: 1400px) {
            // font-size: 1.2rem;
            font-size: 15px;
          }

          @media only screen and (max-width: 900px) {
            // font-size: 1rem;
            font-size: 15px;
          }

          @media only screen and (max-width: 575px) {
            font-size: 1rem;
            margin-top: 25px;
            margin-bottom: 15px;
          }
        }

        .formContent3 {
          font-family: Verdana, Geneva, sans-serif;
          font-size: 15px;

          @media only screen and (max-width: 1700px) {
            // font-size: 1.3rem;
            font-size: 15px;
          }

          @media only screen and (max-width: 1400px) {
            // font-size: 1.2rem;
            font-size: 15px;
          }

          @media only screen and (max-width: 900px) {
            // font-size: 1rem;
            font-size: 15px;
          }

          @media only screen and (max-width: 575px) {
            font-size: 15px;

            position: absolute;
            bottom: 0;
          }
        }

        @media only screen and (max-width: 575px) {
          width: 100%;
          height: auto;
        }
      }

      .formUser {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        @media only screen and (max-width: 575px) {
          width: 95%;
          height: auto;
        }

        .formUserFoot {
          background-color: white !important;
          font-weight: bold !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin: 0 !important;
          // display: flex;
          // border: 2px solid red;

          .btnCol {
            padding: 0 !important;
            margin: 0 !important;

            // button {
            //     width: 150px;
            //     height: 45px;
            // }

            // button {
            //     width: 100px;
            // }

            .cancelBtn {
              width: 100px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .leaflet-container {
    height: 100%;
  }

  .leaflet-control-attribution.leaflet-control {
    display: none;
  }

  .toastAlert {
    margin-top: 60px;
  }

  .Toastify__toast-container.Toastify__toast-container--top-right.toastAlert {
    z-index: 99999;

    white-space: pre-line;
  }
}

.pslamLogo img {
  width: 80px;
}




.leaflet-popup-content-wrapper {
  // border: 1px solid blue;
  width: 650px;
  position: relative;
  // z-index: 1000; /* Adjust the z-index value as needed */
  padding: 20px;

  .leaflet-popup-content{
    // border: 2px solid green;
    padding: 10px;
    // width: 100%;
    margin: 0 auto;
    width: auto !important;

    position: static;

  .userdata-uploadform {
  // border: 2px solid red !important;
  width: 100% !important;
}



}

}