.navbar {
    /* background-color: blue; */
    /* border: 2px solid blue; */
    background-color: white;
    /* background: rgb(255,255,255); */
    /* background: linear-gradient(180deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0.6054796918767507) 100%); */
    z-index: 999;
    box-shadow: 0px 20px 20px 0px rgb(255 255 255 / 100%);
}

.saveAllLogo {
    width: 95px;
    margin-left: 15px;
    position: relative;
    top: 5px;
}

.comingSoon .comesoon {
    /* font-family: "Trebuchet MS"; */
    font-family: Verdana, Geneva, sans-serif;
    font-size: 40px;
    font-weight: 600;
    /* color: red; */
    margin-left: 73px;
}

.comingSoon {
    /* background-image: url(../assets/images/Banner.JPG); */
    /* min-height: 150px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    border: 3px solid; */
}

.comingSoon .saveAllBanner {

}

@media only screen and (max-width: 600px) {
    .comingSoon .comesoon {
        /* font-family: "Trebuchet MS"; */
        font-family: Verdana, Geneva, sans-serif;
        font-size: 24px;
        /* margin-right: 55px;
        margin-top: 15px; */

        /* color: red; */
    }
}


@media screen and (min-width: 300px) and (max-width: 768px) {

    .comingSoon .comesoon {
        /* font-family: "Trebuchet MS"; */
        font-family: Verdana, Geneva, sans-serif;
        font-size: 20px;
        margin-left: 50px;

        /* border: 1px solid green; */
        /* color: blue; */
    }
    .formContainer{
        /* height: 100px !important; */
        position: relative !important;
        top: 30px !important;
        /* padding-top: 90px !important; */
    }
}




/* navigation */
.navbar-collapse {
    flex-grow: 0 !important;
}

.navbar-nav {
    display: flex;
    align-items: center;
}

.navbar-nav li {
    padding: 0 10px;
}

.navbar-collapse a {
    /* color: white !important; */
    font-size: 18px;
}

.navbarHeadC {
    /* color: white; */
}

.navbarHeadC a {
    /* color: white; */
}

.upload-btn a button{
    /* color: green !important; */
    position: relative;
    right: 90px;
}

ul.navbar-nav.ml-auto {
    margin-right: 80px;
}

.custom-file-button {
    width: 460px;
    height: 60px;
    margin-top: 10px;
    padding: 10px 15px;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
}




