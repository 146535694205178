/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    width: 50%;
    left: 600px;
    bottom: 250px;
    margin: 0 auto;
    width: 750px;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    display: flex;
    justify-content: center;

  }
  
  .close-icon {
  
    content: 'x';
    cursor: pointer;
    position: fixed;
    
    right: calc(-345% - 0px);
    top: calc(74vh - 85vh - 12px);

    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }

  
  /* .card.formUser.col-4  */
  .userform{
    /* height: 100% !important;
    width: 53% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
    border: none !important; */

}

.card-footer.row.formUserFoot {
    background: #fff;
    border-top: none;
}
